<template>
  <div class="manage_public wrapper">
    <proTitle></proTitle>
    <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
      <div class="formInline-left">
        <el-form-item label="课程类型 :">
          <el-select v-model="formInline.type" placeholder="课程类型" @change="filterCourse()" style="width: 120px;">
            <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label>
          <el-input @change="filterCourse()" v-model="formInline.search" size="medium" placeholder="输入课程名称/主讲教师/课程标签"
            suffix-icon="el-icon-search"></el-input>
        </el-form-item>
      </div>
      <div class="formInline-right flex-align-between">
        <div class="form-inline-item csp">
          <i class="el-icon-circle-plus-outline"></i>
          <span class="csp" @click="$router.push('/public/create?breadNumber=4')">创建课程</span>
        </div>
      </div>
    </el-form>

    <div class="table_section">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="number" label="序号" width="80" align="center" type="index">
        </el-table-column>
        <el-table-column prop="id" label="课程编码" width="100" align="center">
        </el-table-column>
        <el-table-column prop="name" label="课程名称" width="300" align="center">
        </el-table-column>
        <el-table-column prop="typeName" label="课程类型" width="120" align="center">
        </el-table-column>
        <el-table-column prop="teacherName" label="主讲老师" width="120" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
            <el-button @click="$router.push(`/public/edit/${scope.row.id}?breadNumber=4`)" type="text" size="small">编辑
            </el-button>
            <el-button type="text" size="small" @click="deleteCourse(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
        :pageSizes="[8,16,24]" @pagination="handlePageChange">
      </pagination>
    </div>
  </div>
</template>

<script>
  export default {
    inject: ['reload'],
    data() {
      return {
        tableData: [],
        formInline: {
          type: "",
          time: "",
          search: ""
        },
        pages: { //分页
          currentPage: 1,
          pageSize: 8,
          total: 0,
        },
        typeList: [] //课程类型列表
      };
    },
    watch: {
      '$store.state.user.currentProject.id': {
        handler(newVal, oldVal) {
          if (!newVal) return;
          this.pages.currentPage = 1;
          this.getPublicList();
          this.getTypeList();
        }
      }
    },
    computed: {},
    methods: {
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        // console.log(this.pages);
        this.getPublicList();
      },
      // 查看点击
      handleClick(tab, event) {
        // console.log(tab, event);
        this.$router.push(`/public/detail/${tab.id}?breadNumber=4`);
      },
      // 筛选课程 [包括：筛选课程类型，筛选课程时间，模糊查询课程] 
      filterCourse() {
        this.getPublicList();
      },
      // 删除课程
      deleteCourse(id) {
        // console.log(id);
        this.$confirm('是否删除该公开课?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$Api.Course.publicCourseDel(id).then((resData) => {
            // console.log(resData)
            if (resData.code === 200 && resData.msg === "请求成功") {
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              setTimeout(() => {
                this.reload();
                this.getPublicList();
              }, 100)
            } else {
              this.$message.error(resData.msg);
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取公开课数据列表
      async getPublicList() {
        let params = {
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
        }
        // console.log(params);
        this.formInline.type ? params.courseTypeId = this.formInline.type : '';
        this.formInline.time ? params.query = this.formInline.time : '';
        this.formInline.search ? params.query = this.formInline.search : '';
        let resData = await this.$Api.Course.getPublicList(params);
        this.tableData = resData.data.list;
        this.pages.total = resData.data.totalCount;
      },
      //课程类型列表
      async getTypeList() {
        let resData = await this.$Api.Course.TypeList();
        // console.log('课程类型',resData)
        let arr = [{
          typeName: '全部',
          id: 0
        }]
        this.typeList = arr.concat(resData.data);
      },
    },
    mounted() {
      this.getPublicList();
      this.getTypeList();
    },
    activated() {
      // this.getPublicList();
      // this.getTypeList();
      if (this.$store.state.basics.isRefresh) {
        if (this.$store.state.basics.isCreate) this.pages.currentPage = 1;
        this.getPublicList();
        this.$store.commit('getRefreshState', false);
        this.$store.commit('getCreateState', false);

      }
    }
  };
</script>

<style lang="less" scoped>
  @import "../less/table.less";

  .manage_public {
    overflow: hidden;
    box-sizing: border-box;

    .table_section {
      margin: 20px 0 24px;
    }
  }

  @media screen and (min-width: 1250px) {}
</style>